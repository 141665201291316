
const showAlert = (title: string, message: string) => {
    alert(`${title}: ${message}`);
}


const errorHandler = (title: any | null = null, err: any) => {
    const error = err.response.data;
    let errMessage = error !== undefined ? error.detail || 'Something went wrong, please try again later !' : 'Something went wrong, please try again later !';

    console.log(title, error.detail || errMessage);

    try {

        switch (error.status) {
            case 400:
                showAlert(title || 'Bad Request', errMessage || 'Please check the form for errors !');
                break;
            case 401:
                showAlert('Session Error', errMessage || 'Session expired, please login again !');
                break;
            case 403:
                showAlert('Authorization Error', errMessage || 'You are not authorized to access this resource !');
                break;
            case 404:
                showAlert('Resource Error', errMessage || 'Resource not found !');
                break;
            case 405:
                showAlert('Method Not Allowed', errMessage || 'Method not allowed !');
                break;
            case 500:
                showAlert('Server Error', errMessage || 'Internal server error !');
                break;
            case 501:
                showAlert('Server Error', errMessage || 'Not implemented !');
                break;
            case 502:
                showAlert('Server Error', errMessage || 'Bad Gateway !');
                break;
            case 503:
                showAlert('Server Error', errMessage || 'Service unavailable !');
                break;
            case 504:
                showAlert('Server Error', errMessage || 'Gateway Timeout !');
                break;
            default:
                showAlert(title || 'Error', errMessage || 'Something went wrong, please try again later !');
                break;
        }
    } catch (error) {
        showAlert(title || 'Error', errMessage || 'Something went wrong, please try again later !');
    }

    return

}

export default errorHandler;