import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import "./styles.css";
import Markdown from "../markdownRenderer/index";
import { AppSvg } from '../../assets/svg';

interface MessageComponentProps {
  message: {
    isBot: boolean;
    text: string;
  };
  showIcon?: boolean;
  showTyping?: boolean;
  toggleFlagActionsheet: () => void;
  isLastMessage: boolean;
  isGenerating: boolean;
  isError: boolean;
}

export const MessageComponent: React.FC<MessageComponentProps> = ({
  message,
  showIcon,
  showTyping,
  toggleFlagActionsheet,
  isLastMessage,
  isGenerating,
  isError,
}) => {
  const { isBot, text } = message;
  const messageClass = isBot ? "message bot" : "message user";
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleSpeechToggle = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(utterance);
      setIsSpeaking(true);
      utterance.onend = () => setIsSpeaking(false);
    }
  };

  if (!isBot) {
    return (
      <div className={messageClass}>
        <p>{text}</p>
      </div>
    );
  }

  return (
    <div className={messageClass}>
      {showIcon && (
        <img
          src={AppSvg.ChanakyaLogoSmall}
          alt="icon"
          style={{ width: 30, height: 30 }}
        />
      )}
      <div className="message-content">
        <Markdown markdownText={text} />
        {isGenerating && isLastMessage && (
          <div className="generating-label">
            <span className="generating"></span>
            <span>Generating..</span>
          </div>
        )}
        {isLastMessage && !isGenerating && (
          <div className="btn-main">
            <div className="btn-box">
              <button 
                onClick={toggleFlagActionsheet} 
                className="bottom-btn"
                data-tooltip-id="flag-tooltip"
                data-tooltip-content="Feedback"
              >
                <img
                  src={AppSvg.flagIcon}
                  alt="Flag"
                  className="icon"
                />
              </button>
              <Tooltip id="flag-tooltip" />

              <button
                onClick={() => copyToClipboard(text)}
                className="bottom-btn"
                data-tooltip-id="copy-tooltip"
                data-tooltip-content={isCopied ? "Copied!" : "Copy to clipboard"}
              >
                <img
                  src={AppSvg.copyIcon}
                  alt="Copy"
                  className="icon"
                />
              </button>
              <Tooltip id="copy-tooltip" />

              <button
                onClick={handleSpeechToggle}
                className={`bottom-btn ${isSpeaking ? 'stop-playing-btn' : ''}`}
                data-tooltip-id="speech-tooltip"
                data-tooltip-content={isSpeaking ? "Stop speaking" : "Read Aloud"}
              >
                <img
                  src={isSpeaking ?  AppSvg.muteSpeaker : AppSvg.Speaker }
                  alt={isSpeaking ? "Mute" : "Speak"}
                  className="icon"
                />
              </button>
              <Tooltip id="speech-tooltip" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageComponent;
