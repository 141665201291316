import React, { useEffect, useRef, useState } from "react";
import './styles.css';
import { useParams } from "react-router-dom";
import { AppSvg } from "../../../assets/svg";
import { useIsLoggedIn, useUser } from "../../../redux/useStore";
import { staticSuggestions, Strings } from "../../../utils/constants";
import { ChatHeader } from "../../../components";
import { v4 as uuidv4 } from 'uuid';
import { useAxios } from "../../../hooks/useAxios";
import errorHandler from "../../../utils/common/errHandler";
import { MessageComponent } from "../../../components/message";
import { googleSearchEngineURL, streamingChatURL } from "../../../config/envConfig";
import { startEventStream } from "../../../utils/common/eventSource";
import ReportBoxModal from "../../../components/reportBox";
import ConversionLimitModal from "../../../components/ConvLimitReached";
import SubscriptionModal from "../../../components/PremiumSubscriptionModal";
import LoadingModal from "../../../components/loading-modal";

// interface QueueMessage {
//   id: any;
//   text: any;
//   sources: any;
// }

function ChatScreen() {

  const { id } = useParams()
  const isLoggedIn = useIsLoggedIn()
  const user = useUser()
  const axios = useAxios()
  const isProSelected = user?.isProSelected

  // const pathName = window.location.pathname;


  const [input, setInput] = React.useState<string>('')
  const [messages, setMessages] = React.useState<any>([])
  const [chatsLoading, setChatsLoading] = React.useState<boolean>(false)
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false)
  const [showModal, setShowModal] = useState(false);
  const [showAllSources, setShowAllSources] = useState(false);
  const [limitExceedModal, setLimitExceedModal] = useState(false);
  const [chatId, setChatId] = useState<any>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [suggestions, setSuggestions] = useState<any>(null);
  const [loading,setLoading] = useState(false);


  const eventSourceRef = useRef<any | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (id) {
      getChats()
    } else {
      setChatId(null)
      setMessages([])
      setChatsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const closeFeedbackModal = () => {
    setShowModal(false);
  };

  const openFeedbackModal = () => {
    setShowModal(true);
  };


  const handlePremium = () => {
    setShowSubscriptionModal(true);
    setLimitExceedModal(false)
    
  }



  const getChats = async () => {
    setChatsLoading(true);
    await axios
      .get(`/history/${id}/`)
      .then((response) => {
        if (response.data && response.data.messages) {
          // console.log("Chat History Fetched", response.data.messages);
          const formattedMessages = response.data.messages.map(
            (msg: any) => ({
              id: msg.id,
              text: msg.content,
              isBot: msg.role === "assistant",
              created: msg.created,
            })
          );
          setChatId(id);
          setChatsLoading(false);
          setMessages(formattedMessages);
          // console.warn(JSON.stringify(formattedMessages));
        } else {
          setChatsLoading(false);
        }
      })
      .catch((error) => {
        setChatsLoading(false);
        errorHandler('Get Chats', error);
      });
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`suggestions/?limit=${3}`);
        if (response.data && response.data.length > 0) {
          setSuggestions(response.data);
        } else {
          setSuggestions(staticSuggestions);
        }
      } catch (error) {
        setSuggestions(staticSuggestions);
        console.error(`Error fetching Suggestions for the limit set to ${3}:`, error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchSuggestions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const askBot = async (query: string) => {
    let conversation_id = chatId;
    setIsGenerating(true);
    if (conversation_id === null) {

      await axios.get("/chat/")
        .then((response) => {
          conversation_id = response.data.conversation_id;
          setChatId(conversation_id);
        })
        .catch((error) => {
          errorHandler("New Chat", error);
          const msg = {
            id: uuidv4(),
            text: `Strings.errors.networkError`,
            isBot: true,
            isError: true,
            created: new Date(),
          };
          setMessages([...messages, msg]);
          onStopStreaming();
        })
    }

    let token;
    let data;
    let sourceUrl;
    let is_ios = false;

    if (user?.isPro && isProSelected) {
      sourceUrl = googleSearchEngineURL;
    } else {
      sourceUrl = streamingChatURL;
    }
    token = user?.token;
    data = JSON.stringify({ query, conversation_id, is_ios });


    const eventSource = startEventStream(
      sourceUrl,
      token,
      data,
      onMessage,
      onError
    );

    if (eventSource) {
      eventSourceRef.current = eventSource;
    }


  }

  const onMessage = (e: MessageEvent, eventSource: EventSource) => {

    // Check if the data is "[DONE]" before parsing
    if (e.data === "[DONE]") {
      console.log(e.data, "Done");
      onStopStreaming();
      setIsGenerating(false);
      return;
    }


    let parsedData;
    try {
      parsedData = JSON.parse(e.data);
    } catch (error) {
      console.error("Failed to parse message data:", e.data);
      return;
    }

    const userSubscriptionActive = user?.isPro;
    const userSubscriptionIsPro = user?.isProSelected;

    // Checking if both conditions are true
    if (userSubscriptionActive && userSubscriptionIsPro) {
      handlePremiumVersionMessage(e, eventSource);
    }

    if (parsedData.status === "End") {
      onStopStreaming();
      setIsGenerating(false);
    }

    handleNormalChatMessage(e);
  };




  const handlePremiumVersionMessage = (e: MessageEvent, eventSource: EventSource) => {
    const payload = JSON.parse(e.data);
    const { status, step, response, sources, id } = payload;

    // Check if this is a valid Google response message
    if (
      status !== "Processing" &&
      status !== "step" &&
      status !== "End" &&
      step === undefined &&
      !sources
    ) {
      const googleBotMessage = response;
      const googleBotMessageId = id;

      setMessages((prevMessages: any) => {
        const newMessages = [...prevMessages];
        const existingIndex = newMessages.findIndex(msg => msg.id === googleBotMessageId);

        if (existingIndex === -1) {
          newMessages.push({
            id: googleBotMessageId,
            text: googleBotMessage,
            isBot: true,
          });
        } else {
          newMessages[existingIndex].text += googleBotMessage || "";
        }

        return newMessages;
      });

    } else if (sources && !response) {
      const parsedSources = sources
        .split("\n")
        .map((line: string) => {
          const match = line.match(/\[(.+?)\]\((.+?)\)/);
          return match ? match[2] : null;
        })
        .filter(Boolean);

      console.log("Parsed Sources:::", parsedSources);

      // Assuming this is a valid bot message ID and message from a previous step
      const googleBotMessage = response || ''; 
      const googleBotMessageId = id;

      setMessages((prevMessages: any) => {
        const newMessages = [...prevMessages];
        newMessages.push({
          id: googleBotMessageId,
          text: googleBotMessage,
          isBot: true,
          sources: parsedSources
        });
        return newMessages;
      });



    } else if (step === true && !response) {
      // setSearchingWeb(true); 

    } else if (status === "End") {
      onStopStreaming(); 
      setIsGenerating(false);
      console.log("Google Event Stream ended");
    }
  };





  const handleNormalChatMessage = (e: any) => {
    if (e.data !== "[DONE]") {
      const payload = JSON.parse(e.data);

      // Check if choices array exists and has at least one element
      if (payload?.choices && payload.choices.length > 0) {
        const botMessage = payload.choices[0].text;
        const botMessageId = payload.id;
        const sourcesPayload = payload.choices?.[0]?.sources || [];

        setMessages((prevMessages: any) => {
          const newMessages = [...prevMessages];
          const existingIndex = newMessages.findIndex(msg => msg.id === botMessageId);
          if (existingIndex === -1) {
            newMessages.push({
              id: botMessageId,
              text: botMessage,
              isBot: true,
              sources: sourcesPayload,
            });
          } else {
            newMessages[existingIndex].text += botMessage;
            if (sourcesPayload?.length > 0) {
              newMessages[existingIndex].sources = sourcesPayload;
            }
          }
          return newMessages;
        });
      } else {
        console.warn("No choices available in the response", payload);
      }
    }
  };




  const onStopStreaming = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    setIsGenerating(false);
  };

  const onError = (error: any) => {
    console.log("Chat Error", error?.responseCode)

    onChatError(error);
    // Stop generating.....
    setIsGenerating(false);
    onStopStreaming();
  };

  const onChatError = (error: any) => {
    // const code = JSON.parse(error.message).status;
    const myError = JSON.parse(error.data);
    const code = myError?.status || 500;
    let text = myError.detail || null

    if (code === 429) {
      setLimitExceedModal(true);
    }

    if (text === null) {

      switch (code) {
        case 400:
          text = Strings.errors.networkError;
          break;

        case 401:
          text = Strings.errors.sessionExpired;
          break;

        case 404:
          text = Strings.errors.notFound;
          break;

        case 409:
          text = Strings.errors.alreadyExits;
          break;

        case 429:
          text = isLoggedIn ? user?.isPro ? Strings.errors.proLimitReached : Strings.errors.userLimitReached : Strings.errors.guestLimitReached;
          break;

        case 500:
          text = Strings.errors.serverEror;
          break;

        case 503:
          text = Strings.errors.serverEror;
          break;

        default:
          text = Strings.errors.unknownError;
          break;
      }
    }



    if (text) {
      const msg = {
        id: uuidv4(),
        text: text,
        isBot: true,
        isError: true,
        created: new Date(),
      };
      setMessages([...messages, msg]);
    }
  }


  const onSendMessage = async () => {
    if (input === '') {
      return
    }
  
    const newMessage = {
      id: uuidv4(),
      text: input,
      isBot: false,
      created: new Date(),
    };
  
    setMessages([...messages, newMessage])
    setInput('')
    askBot(input)
  }


  const onSuggestionPress = (suggestion: any) => {
    if (!suggestion.value) {
      return;
    }
  
    const newMessage = {
      id: uuidv4(),
      text: suggestion.value,
      isBot: false,
      created: new Date(),
    };
  
    setMessages([...messages, newMessage])
    askBot(suggestion.value)
  }


  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    if (e.target.value === '') {
      resetTextareaHeight();
    } else {
      adjustTextareaHeight();
    }
  };


  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '28px';
      inputRef.current.style.height = `${Math.max(inputRef.current.scrollHeight, 28)}px`;
    }
  };

  const resetTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '28px';
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSendMessage();
    } else if (e.key === 'Backspace' && input.length === 1) {
      setTimeout(resetTextareaHeight, 0);
    }
  };

  


  const extractDomain = (url: any) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname.replace("www.", "");
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  };

  const getFaviconUrl = (domain: any) => {
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
  };


  // const handlePremium = () => {
  //   return <ChatHeader />
  // }



  return (
    <>
      <div className="chat-screen">
        <ChatHeader />

        {!chatsLoading ? (
          messages.length > 0 ? (
            <div className="chat-content filled">
              {messages.map((message: any, index: any) => (
                <MessageComponent
                  key={message.id}
                  message={message}
                  isLastMessage={index === messages.length - 1}
                  isGenerating={false}
                  isError={message.isError}
                  showIcon={true}
                  toggleFlagActionsheet={openFeedbackModal}
                />
              ))}
              {isGenerating && messages.length > -1 && (
                <MessageComponent
                  message={{
                    isBot: true,
                    text: "",
                  }}
                  isLastMessage={true}
                  isGenerating={isGenerating}
                  isError={false}
                  showIcon={messages[messages.length - 1].isBot === false}
                  toggleFlagActionsheet={() => setShowModal(true)}
                />
              )}
              {messages.length > 0 && messages[messages.length - 1].sources && messages[messages.length - 1].sources.length > 0 && (
                <div className="sources-main">
                  <h3 className="source-title">Sources:</h3>
                  <div className="sources-box">
                    {messages[messages.length - 1].sources
                      .slice(0, showAllSources ? messages[messages.length - 1].sources.length : 3)
                      .map((source: any, idx: any) => {
                        const domain = extractDomain(source);
                        return (
                          <a
                            href={source}
                            className="source-btn"
                            key={idx}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={getFaviconUrl(domain)}
                              alt={`${domain} favicon`}
                              className="favicon"
                            />
                            <span className="source-text">{domain}</span>
                          </a>
                        );
                      })}
                    {!showAllSources && messages[messages.length - 1].sources.length > 3 && (
                      <button
                        className="source-btn"
                        onClick={() => setShowAllSources(true)}
                      >
                        <span className="source-text">+{messages[messages.length - 1].sources.length - 3} more</span>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="chat-content empty">
              <h1>Namaste {user?.firstName}</h1>
              <h2>How can I help you today?</h2>
              <div className="suggestion-holder">
              {loading ? (
                  <LoadingModal modalVisible={true} />
                ) : (
                  suggestions?.map((suggestion: any, index: any) => (
                    <div key={index} className="suggestion" onClick={() => onSuggestionPress(suggestion)}>
                      <img src={suggestion.icon} alt="suggestion" style={{ width: 25, height: 25 }} />
                      <span>{suggestion.value}</span>
                    </div>
                  ))
                )}
              </div>
            </div>
          )
        ) : (
          <div className="chat-content empty">
            <img src={AppSvg.loading} alt="loading" style={{ width: '30px', height: '30px' }} />
            <h3 style={{ marginLeft: 15, fontWeight: 'normal' }}>Loading Chats...</h3>
          </div>
        )}

        <ReportBoxModal isOpen={showModal} onClose={closeFeedbackModal} />

        <div className="input-box">
          <textarea
            ref={inputRef}
            className="chat-input"
            value={input}
            placeholder="Type a message..."
            onChange={handleInputChange}
            onKeyDown={onKeyDown}
          />
          {
            isGenerating ?
              <button className="chat-send" onClick={onStopStreaming}>
                <div style={{ width: 17, height: 17, borderRadius:3,background:'white' }}/>
              </button>
              :
              <button
              className="chat-send"
              onClick={onSendMessage}
              disabled={input.trim() === ''}
              style={{
                backgroundColor: input.trim() === '' ? '#FF8A50' : '#FF4A00',
                cursor: input.trim() === '' ? 'not-allowed' : 'pointer',
              }}
            >
            
                <img src={AppSvg.send} alt="send" style={{ width: 25, height: 25 }}  />
              </button>
          }

        </div>
      </div>
      {limitExceedModal && (
        <ConversionLimitModal
          user={user}
          onClose={() => setLimitExceedModal(false)}
          handlePremium={handlePremium}
        />
      )}
      {showSubscriptionModal && !user?.isPro && (
          <SubscriptionModal
            onClose={() => setShowSubscriptionModal(false)}
          />
        )}

    </>
  );
};

export default ChatScreen;