import { SSE } from "sse.js";

const startEventStream = (
  url,
  token,
  data,
  onMessage,
  onError
) => {
  if (!token) {
    console.error("Token is null. Unable to set Authorization header.");
    return;
  }

  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
    };
  
    const eventSource = new SSE(url, {
      headers: headers,
      method: "POST",
      payload: data,
    });

    eventSource.addEventListener("message", (e) => {
      onMessage(e, eventSource);
    });

    eventSource.addEventListener("error", (error) => {
      console.error("Event stream error:", error);
      if (onError) {
        onError(error);
      }
      eventSource.close();
    });

    eventSource.stream(); // Start the event stream explicitly

    return eventSource;  

  } catch (error) {
    console.error("Caught error in startEventStream:", error);
    
    if (error.response) {
      const { status } = error.response;
      if (status === 429) {
        console.error("Too many requests. Rate limit exceeded.");
        // Optionally handle retry logic here
      } else if (status >= 500) {
        console.error(`Server error with status ${status}`);
      }
    } else {
      console.error("Unknown error:", error.message || error);
    }
    
    // Optionally log the error to a monitoring service (like Sentry)
    // Sentry.captureException(error);
  }
};

const stopEventStream = (eventSource) => {
  if (eventSource) {
    eventSource.close();
  }
};

export { startEventStream, stopEventStream };
